// Brand Colors
$blue-payee: #455bed;
$blue-king: #1089ff;
$brand-tertiary: #e8ebf0;
$brand-quaternary: #132043;

// Success
$success-primary: #26776d;

// Error
$error-primary: #e0301e;

// Warning
$warning-primary: #fedacc;

// Blacks & Whites
$white: #ffffff;
$grey-light: #ededf6;
$grey-medium: #b4b4b4;
$grey-dark: #6d6b6b;
$black: #000000;

// Alert Colors
$alert-red: #e26c6c;
$alert-green: #1AC582;
$alert-yellow: #ffecd0;

$blue-payee-contrast: white;
$blue-king-contrast: white;
$white-contrast: black;
$grey-light-contrast: $grey-dark;
$grey-medium-contrast: white;
$grey-dark-contrast: white;
$black-contrast: white;
$alert-red-contrast: white;
$alert-green-contrast: white;
$alert-yellow-contrast: white;
