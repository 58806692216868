@import "src/assets/shared/theme/payee-theme";
@import "src/assets/shared/theme/reset";
@import "src/assets/shared/theme/texts";
@import "src/assets/shared/theme/helper-classes";
@import "src/assets/shared/theme/payee-elements";

.user-guide-container {
  .warn-text {
    font-weight: bold;
    color: red;
  }

  .mw-50 {
    max-width: 50px !important;
  }

  .mw-200 {
    max-width: 200px !important;
  }
  .mw-300 {
    max-width: 300px !important;
  }
  .mw-400 {
    max-width: 400px !important;
  }
  .mw-500 {
    max-width: 500px !important;
  }
  .mw-600 {
    max-width: 500px !important;
  }
  .mw-800 {
    max-width: 800px !important;
  }

  .mw-1000 {
    max-width: 1000px !important;
  }

  .mw-1200 {
    max-width: 1200px !important;
  }

  .mw-1400 {
    max-width: 1400px !important;
  }

  .d-inline-block-middle {
    display: inline-block !important;
    vertical-align: middle;
  }

  .d-block {
    display: block !important;
  }

  .d-block-center {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }

  .chapter-content img {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  ul {
    li.l2 {
      margin-left: 40px;
    }
    li.l3 {
      margin-left: 80px;
    }
  }
}
